import 'css/base/variables.css'; // variaveis
import 'css/vendor/tippy-js.css';
import 'tippy.js/animations/shift-away-subtle.css';
import "css/index.css"; // entrada


document
	.addEventListener("DOMContentLoaded", () =>
		document.body.classList.add("DOMContentLoaded")
	)


